import React, { useState } from 'react'
import { useForms } from '../hooks/useForms'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const ResearchPaperForm = ({pdf}) => {
  const { categories } = useForms()
  const [value, setValue] = useState({})
  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...value,
      }),
    })
      .then(() => {
        form.reset()
        setSuccess(true)
      })
      .catch(error => alert(error))
  }

  return (
    <div className="w-full md:mt-10 md:max-w-[50%] mx-auto pb-10 bg-white basis-2/4 max-w-screen-sm">
      {!success ? (
        <>
          <form
            name="researchPaperForm"
            method="POST"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className='text-center justify-center max-w-screen-sm mx-0 md:mx-1'
          >
            <input type="hidden" name="form-name" value="researchPaperForm" />
            <label className="hidden">
              Don't fill this out
              <input name="bot-field" onChange={handleChange} />
            </label>
            <h2 className="body-sm normal-case text-left mb-10 mt-2">Please enter the information below to download the research report.</h2>
            <div className="flex flex-col pt-10 md:py-0 md:mx-auto">
              <div className="flex flex-col md:flex-row md:justify-between">
                <input
                  type="text"
                  name="firstName"
                  autoComplete="off"
                  onChange={handleChange}
                  className="body mb-5 border-2 md:border-[3px] py-[1.2%] focus-field bg-transparent text-center md:w-[48%]"
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  autoComplete="off"
                  onChange={handleChange}
                  className="body mb-5 border-2 md:border-[3px] py-[1.2%] focus-field bg-transparent text-center md:w-[48%]"
                  placeholder="Last Name"
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                required
                placeholder="Your Email"
                onChange={handleChange}
                className="body mb-5 border-2 md:border-[3px] py-[1.2%] focus-field bg-transparent text-center"
                autoComplete="off"
              />
              <select
                name="category"
                required
                onBlur={handleChange}
                className="signup-select"
              >
                <option value="" defaultValue className="">
                  Which best describes you?
                </option>
                {categories?.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="mx-auto bg-tertiary rounded-md whitespace-nowrap z-10 w-64 h-16 lg:w-72 lg:pt-1 h6"
              type="submit"
            >
              Download the Report
            </button>
          </form>
        </>
      ) : (
        <div className="flex normal-case justify-center items-center px-10 min-h-[620px] md:min-h-0 md:my-[8%]">
          <h2 className="body-sm normal-case w-3/4 leading-relaxed text-center">
            Thank you. Click <a href={pdf} target="_blank" rel="noopener noreferrer" className="cursor-pointer border-b">here</a> for the report. We're also sending you a
            download link to your email.
          </h2>
        </div>
      )}
    </div>
  )
}
